import { render, staticRenderFns } from "./BookCardComponent.vue?vue&type=template&id=52727a37&scoped=true&"
import script from "./BookCardComponent.vue?vue&type=script&lang=js&"
export * from "./BookCardComponent.vue?vue&type=script&lang=js&"
import style0 from "./BookCardComponent.vue?vue&type=style&index=0&id=52727a37&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52727a37",
  null
  
)

export default component.exports