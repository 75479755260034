<template>
<div class="header mt-24">

  <div class="container mx-auto my-12"> 
    <div class="text-center bg-gray-400 rounded-md mx-4">
      <p class="text-2xl text-text-gray-600 py-4">لم تجد كتابك! تواصل معنا</p>
      <div class="flex justify-center content-center items-center py-2">
          <a
            href="https://api.whatsapp.com/send?phone=212699994515&text= مرحبا، أبحث عن كتاب"
            target="_blank"
            class="bg-gray-500 hover:bg-gray-600 text-white text-center font-semibold py-2 px-4 rounded-full shadow-lg font-sans mb-2 ml-2 md:max-w-xs md:ml-4"
          >
            <i class="icon ion-logo-whatsapp text-2xl ml-4"></i>
            <span>الواتساب</span>
          </a>
          <a
            href="https://t.me/kotob_ma"
            target="_blank"
            class="bg-gray-500 hover:bg-gray-600 text-white text-center font-semibold py-2 px-4 rounded-full shadow-lg font-sans mb-2 ml-2 md:max-w-xs"
          >
            <i class="icon ion-md-send text-2xl ml-4"></i>
            <span>التيلقرام</span>
          </a>
      </div>
    </div>

    <div class="container mx-auto my-12">
      <div class="flex justify-center items-center content-center">
        <a class="text-3xl px-4" target="_blank" href="https://www.instagram.com/kotob.site"><i class="icon ion-logo-instagram"></i></a>
        <a class="text-3xl px-4" target="_blank" href="https://www.facebook.com/kotob.site"><i class="icon ion-logo-facebook"></i></a>
        <a class="text-3xl px-4" target="_blank" href="https://api.whatsapp.com/send?phone=212699994515"><i class="icon ion-logo-whatsapp"></i></a>
        <a class="text-3xl px-4" target="_blank" href="https://t.me/kotob_ma"><i class="icon ion-md-send"></i></a>
      </div>
    </div>
    
  </div>

    <div class="container mx-auto mt-9">
      <p class="text-center text-gray-600">إقرأ كي تحيا</p>
      <p class="text-center text-gray-600"><span class="text-red-500">❤️</span> kotob.ma</p>
    </div>

</div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>