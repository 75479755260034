<template>
  <div class="about container mx-auto bg-white p-5 " >
    <h1 class="font-sans font-semibold text-xl leading-loose">عن موقع كتب</h1>
    <p class="text-lg">أطلب كتابك اﻵن، بسهولة</p>
  </div>
</template>
<script>
export default {
  name: "about"
}
</script>
