import Vue from 'vue'
import Vuex from 'vuex'
import bookList from '@/data/books.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    books: bookList
  },
  
  getters: {
    books: state => state.books,
    bookById: (state) => (id) => {     
      return state.books.find(book => book.id == id)
    },
  },

  mutations: {
    loading(state, isLoading) {
      state.isLoading = isLoading
    }
  },

  actions: {
    getAllBooks() {
      return new Promise(() => {

      })
    },

    searchByTitle( {getters, commit}, searchedTitle ) {
      return new Promise((reslove) => {
        commit('loading', true)
        reslove(getters.books.filter(book => book.title.includes(searchedTitle)))
      }).finally(() => commit('loading', false))
    }
  },

  modules: {
  }
})
