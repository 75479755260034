<template>
  <div id="app">
    <header-component></header-component>
    <div class="h-20"></div>
    <router-view/>
    <FooterVue></FooterVue>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterVue from './components/Footer.vue'

export default {
  components: { HeaderComponent, FooterVue }
}
</script>

<style lang="scss">


</style>
