import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
var firebase = require("firebase/app");
require("firebase/analytics");

  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyAwfAIae7t888rc0xit-NJHL6CYomnXAL8",
    authDomain: "kotob-79f3b.firebaseapp.com",
    databaseURL: "https://kotob-79f3b.firebaseio.com",
    projectId: "kotob-79f3b",
    storageBucket: "kotob-79f3b.appspot.com",
    messagingSenderId: "908151577444",
    appId: "1:908151577444:web:a2b44d67b6df07109a3871",
    measurementId: "G-WN891TVJXF"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
