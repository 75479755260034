<template>
  <div class="container mx-auto bg-white rounded-lg overflow-hidden" v-if="book">
    <div class="flex flex-wrap px-3">
      <div class="w-full text-center md:w-1/3">
        <img :src="book.image" class="book-image mx-auto my-4 rounded-l shadow-xl" :alt="book.title" :title="book.title" />
      </div>
      <div class="w-full p-4 text-right md:w-2/3">
        <h1 class="text-right font-semibold font-sans text-3xl mb-5">{{ book.title }}</h1>
        <p class="text-justify text-xl">{{ book.description }}</p>

        <div class="font-semibold text-3xl mt-2 text-gray-700 my-5">
          {{ book.price }}
          <span>درهم</span>
        </div>

        <div class="flex flex-wrap">
          <a
            :href="whatsappLink"
            target="_blank"
            class="w-full bg-green-500 hover:bg-green-700 text-white text-center font-semibold py-4 px-8 rounded-full shadow-lg font-sans mb-2 md:max-w-xs md:ml-4"
          >
            <i class="icon ion-logo-whatsapp text-2xl ml-4"></i>
            <span>شراء عبر الواتساب</span>
          </a>

          <a
            href="https://t.me/kotob_ma"
            target="_blank"
            class="w-full bg-blue-500 hover:bg-blue-700 text-white text-center font-semibold py-4 px-8 rounded-full shadow-lg font-sans mb-2 md:max-w-xs"
          >
            <i class="icon ion-md-send text-2xl ml-4"></i>
            <span>شراء عبر تيلقرام</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Book",

  data: function() {
    return {
      id: null,
      book: null,
      whatsappLink: null
    };
  },

  computed: {
    ...mapGetters(["bookById"])
  },

  watch: {
    "$route.params.id": function () {
      this.id = this.$route.params.id;
      this.loadBook(this.id);
    }
  },

  mounted() {
    this.id = this.$route.params.id;
    this.loadBook(this.id);
  },

  methods: {
    loadBook(bookId) {
      this.book = this.bookById(bookId);
      this.buildWhatsappLink();
      window.scrollTo(0,0)
    },

    buildWhatsappLink() {
      this.whatsappLink = `https://api.whatsapp.com/send?phone=212699994515&text= مرحبا، أريد شراء كتاب ${this.book.title}`;
    }
  }
};
</script>

<style scoped>
@media (min-width: 768px) { 
.book-image {
  max-height: 500px;
}
}
</style>