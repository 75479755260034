<template>
<div>
  <div class="home">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <BookCardComponent v-for="book in books" :key="book.id" class="my-4" :book="book" /> 
      </div>
    </div>
  </div>

</div>
</template>

<script>
import BookCardComponent from '@/components/BookCardComponent'
import { mapState } from 'vuex'

export default {
  name: 'home',
  components: {
    BookCardComponent
  },

  computed: {
    ...mapState(['books'])
  },

  mounted() {

  }
}
</script>
