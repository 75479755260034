<template>
  <header class="px-4 text-white shadow-md w-full fixed top-0">
    <div class="container mx-auto">
      <div class="flex items-center content-around justify-between h-16">
        <router-link :to="{name: 'home'}">
          <img src="@/assets/logo.png" class="h-10" alt="kotob كتب" />
        </router-link>
        <div class="flex items-center">
          <router-link class="hidden md:block ml-6 font-sans font-semibold" to="/">الرئيسية</router-link>
          <div class="relative mr-6 my-2">
            <input
              type="search"
              @input="searchBook"
              @click="focusSearchBook"
              v-model="bookSearchTitle"
              class="bg-white w-full md:w-full text-black shadow rounded-full border-0 pr-10 pl-5 py-4 h-10 outline-none"
              placeholder="البحث عن كتاب..."
            />
            <div class="absolute top-0 right-0 mr-4 mt-2">
              <i class="icon ion-md-search text-orange-600"></i>
            </div>
            <div
              v-show="showSearch"
              class="absolute h-64 bg-white mt-1 rounded-lg w-full shadow-xl top-0 right-0 mt-12 overflow-y-scroll" @click="showSearch = false"
            >
              <div v-if="isLoadingSearch">
                <p>جاري البحث...</p>
              </div>

              <div v-else-if="!isLoadingSearch && (searchedBooks.length > 0)">
                <div v-for="book in searchedBooks" :key="book.id" @click="goToBook(book.id)">
                <div class="flex p-2 mb-2 content-center items-center cursor-pointer" >
                  <div class>
                    <img :src="book.image" class="h-24" />
                  </div>
                  <div>
                    <h3 class="text-black mr-4 font-sans truncate">{{ book.title }}</h3>
                  </div>
                </div>
                <div class="w-11/12 mx-auto border-b-2 border-gray-200"></div>
                </div>
              </div>

             <div v-else-if="bookSearchTitle.length > 1 && !isLoadingSearch && (searchedBooks.length === 0)"
                                 class="flex items-center justify-center content-center h-full">
                  <div class="text-gray-600">لم يتم العثور على الكتاب</div>
              </div>              

            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderComponent",

  data: function() {
    return {
      showSearch: false,
      isLoadingSearch: false,
      bookSearchTitle: "",
      searchedBooks: []
    };
  },

  methods: {
    ...mapActions(["searchByTitle"]),

    searchBook() {

      if (!this.showSearch) {
        this.toggle()
      }

      this.isLoadingSearch = true;
      this.searchByTitle(this.bookSearchTitle)
        .then(data => {
          this.searchedBooks = data;
        })
        .finally(() => (this.isLoadingSearch = false));
    },

    focusSearchBook() {
      if (this.bookSearchTitle.length > 0 && !this.showSearch ) {
        // document.documentElement.removeEventListener('click', this.toggle)
        // document.documentElement.addEventListener('click', this.toggle)
        this.toggle()
      }
    },

    toggle() {     
      this.showSearch = !this.showSearch
      if (this.showSearch) {
        document.documentElement.addEventListener('click', this.toggle, {capture: true})        
      } else {
        document.documentElement.removeEventListener('click', this.toggle, {capture: true})
      }
    },

    goToBook(bookId) {
      if (this.showSearch) {
        this.toggle()
      }

      this.$router.replace({name: 'book', params: {id: bookId } })
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background-color: #f58f29;
}
</style>